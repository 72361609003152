import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { 
  LandingPage, 
  ReadSecretPage,
  TermsAndConditionsPage 
} from "./containers";

import { TopBar, Footer } from "./components";

function App() {
  return (
    <Router>
      <div>
        <TopBar />
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/s">
            <ReadSecretPage />
          </Route>
          <Route path="/terms">
            <TermsAndConditionsPage />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}
export default App;