import React, { Component } from 'react'
import './InsertSecretBar.css';
import { backendUrl } from '../../conf'
import { Formik, Field, Form } from 'formik'

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  WhatsappIcon
} from "react-share";

class InsertSecretBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      isDisabled: false,
      value: '',
      shareUrl: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async postData(url = '', data = {}) {
    const encodedSecret = btoa(data.secret)
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        secretContent: encodedSecret
      })
    });

    const json = await response.json()
    return json;
  }

  handleChange(event) {
    event.preventDefault();
    this.setState({ value: event.target.value });
  }

  handleSubmit(formData) {
    this.setState({
      isDisabled: true
    });
    this.postData(backendUrl, formData)
      .then(response => {
        this.setState({ value: response.data.id, shareUrl: `${window.location.href}s?mySecret=${response.data.id}`, isDisabled: false });
      })
  }

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
  }

  render() {
    if (!this.state.value) {
      return (
        <div className='InsertSecretBar wrapper style3 InsertSecretBar-input-panel image'>
          <Formik
            initialValues={{ secret: "" }}
            onSubmit={this.handleSubmit}
            >
            <Form>
              <p>
                <Field style={{ resize: 'none' }}
                  component="textarea" 
                  rows='4'
                  className='input' 
                  placeholder='Insert your secret here' 
                  name="secret" 
                  type="text" />
              </p>
              
              <span>
                <button id='submitSecretButton' className='button primary icon solid' type="submit" disabled={this.state.isDisabled}>Get Secret Link</button>
              </span>
            </Form>
          </Formik>

        </div >

      );
    } else {
      return (
        <div className="InsertSecretBar InsertSecretBar-output-panel">
          <div className="wrapper style2">
            <p>
              <a
                className="InsertSecretBar-link"
                href={this.state.shareUrl}>

                {this.state.shareUrl}

              </a>
            </p>


            <p>
              <textarea value={this.state.shareUrl} onChange={this.handleChange} />
            </p>

          </div>

          <span>
            <EmailShareButton
              url={this.state.shareUrl}
              subject="Checkout this secret"
              body={this.state.value}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>

            <FacebookShareButton
              url={this.state.shareUrl}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>

            <WhatsappShareButton
              url={this.state.shareUrl}
              title="mySharedPage"
              separator=" "
              // openShareDialogOnClick={false}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </span>
        </div>
      )
    }
  }
}

export default InsertSecretBar;