import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    render() {
        return (
            <footer id="footer">
				<ul className="icons">
					<li><a href="/" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
					<li><a href="/" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
					<li><a href="/" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
				</ul>
				<p className="copyright">&copy; Untitled. Credits: <a href="http://html5up.net">HTML5 UP</a></p>
			</footer>
        )
    }
}

export default Footer;