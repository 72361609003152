import React, { Component } from 'react';
import { ReactComponent as Logo } from './images/logo.svg';

import './LandingPageHeroSection.css';
// import logo from './images/logo.svg';

import { InsertSecretBar } from '../';

class LandingPageHeroSection extends Component {
    render() {
        return (
            <div className="LandingPageHeroSection">
                <div className="content">
                    
                        <div className="HeroBigLogo">
                            <a href="/">
                                <Logo className="HeroBigLogo__svg" />
                            </a>
                        </div>
                        <p>Share your secrets securely:<br />
                        deleted after reading like in spy movies</p>
                        {/* <ul className="actions">
                            <li><a href="/#" className="button primary icon solid fa-download">Download</a></li>
                            <li><a href="/#one" className="button icon solid fa-chevron-down scrolly">Learn More</a></li>
                        </ul> */}

                </div>
                <InsertSecretBar />
                {/* <div className="image phone"><div className="inner"><img src="images/screen.jpg" alt="" /></div></div> */}
            </div>
        )
    }
}

export default LandingPageHeroSection