import React, { Component } from 'react';
import './TopBar.css';


class Hamburger extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            active: false,
            navBarActive: false
        };
        this.navMenuElement = React.createRef();
    }
    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState, navBarActive: !currentState });
        this.navMenuElement.current.toggleClass()
    };

    render() {
        const currentState = this.state.active;

        return (
            <div>
                <div 
                    className={`TopBar-hamburger ${this.state.active ? 'active': null}`} 
                    onClick={this.toggleClass} 
                >
                    <span className="TopBar-bar"></span>
                    <span className="TopBar-bar"></span>
                    <span className="TopBar-bar"></span>
                </div>
                <NavMenu ref={this.navMenuElement} active={currentState} />
            </div>
        )
  }
}

class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            active: props.active,
        };
    }
    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    };

    render() {

        return (
            <ul className={`TopBar-nav-menu ${this.state.active ? 'active': null}`}>
                <li className="TopBar-nav-item">
                    <a href="/" className="TopBar-nav-link">Home</a>
                </li>
                <li className="TopBar-nav-item">
                    <a href="/terms" className="TopBar-nav-link">Terms</a>
                </li>
                <li className="TopBar-nav-item">
                    <a href="/" className="TopBar-nav-link">About</a>
                </li>
                <li className="TopBar-nav-item">
                    <a href="/" className="TopBar-nav-link" >Contact</a>
                </li>
            </ul>
        )
    }
}

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            hamburgerVisible: false,
        };
        if (window.screen.width < 768) {
            this.state.hamburgerVisible = true;
        }
    }

    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        return (
            <header className="TopBar-header">
                <nav className="TopBar-navbar">
                    <a href="/" className="TopBar-nav-logo">Sealedtext</a>
                    <Hamburger />
                </nav>
            </header>
        );
    }
}  


export default TopBar;