import React, { Component } from 'react';
import './LandingPageContentSection.css';
import binaryImage from './images/binary.jpg';
import chatAppsImage from './images/chat_app.jpg';
import credentialsImage from './images/credentials.jpg';

class LandingPageContentSection extends Component {
    render() {
        return (
            <div className='LandingPageContentSection'>
            {/* // <!-- One --> */}
                <section id="one" className="wrapper style2 special">
                    <header className="major">
                        <h2>Insert your secrets and generate a sharable link<br />
                        When opened your message is showed once and deleted</h2>
                    </header>
                    <ul className="icons major">
                        <li><span className="icon solid fa-cloud-upload-alt"><span className="label">Upload</span></span></li>
                        <li><span className="icon solid fa-lock"><span className="label">Encrypt</span></span></li>
                        <li><span className="icon solid fa-share"><span className="label">Share</span></span></li>
                    </ul>
                </section>

            {/* // <!-- Two --> */}
                <section id="two" className="wrapper">
                    <div className="inner alt">
                        <section className="spotlight">
                            <div className="image"><img src={credentialsImage} alt="" /></div>
                            <div className="content">
                                <h3>Insert your secret messages</h3>
                                <p>Insert any kind of message you want to be read only by your receiver in the box on top of the page. Click the "Generate secret link" button and let us do the magic.</p>
                            </div>
                        </section>
                        <section className="spotlight">
                            <div className="image"><img src={binaryImage} alt="" /></div>
                            <div className="content">
                                <h3>Generate the link.</h3>
                                <p>Once the button has been pushed we will encode your message and generate a link to retrive it. Once the link has been clicked the message will be showed only one time and then destroyed.</p>
                            </div>
                        </section>
                        <section className="spotlight">
                            <div className="image"><img src={chatAppsImage} alt="" /></div>
                            <div className="content">
                                <h3>Share it with the receiver</h3>
                                <p>You can share the link on the most common platforms or get the link and share in any way you like. It can be opened by any device that has an Internet browser like Google Chrome, Firefox, Safari or any other.</p>
                            </div>
                        </section>
                        {/* <section className="special">
                            <ul className="icons labeled">
                                <li><span className="icon solid fa-camera-retro"><span className="label">Ipsum lorem accumsan</span></span></li>
                                <li><span className="icon solid fa-sync"><span className="label">Sed vehicula elementum</span></span></li>
                                <li><span className="icon solid fa-cloud"><span className="label">Elit fusce consequat</span></span></li>
                                <li><span className="icon solid fa-code"><span className="label">Lorem nullam tempus</span></span></li>
                                <li><span className="icon solid fa-desktop"><span className="label">Adipiscing amet sapien</span></span></li>
                            </ul>
                        </section> */}
                    </div>
                </section>

            {/* // <!-- Three --> */}
                {/* <section id="three" className="wrapper style2 special">
                    <header className="major">
                        <h2>Magna leo sapien gravida</h2>
                        <p>Gravida at leo elementum elit fusce accumsan dui libero, quis vehicula<br />
                        lectus ultricies eu. In convallis amet leo sapien iaculis efficitur.</p>
                    </header>
                    <ul className="actions special">
                        <li><a href="/" className="button primary icon solid fa-download">Download</a></li>
                        <li><a href="/" className="button">Learn More</a></li>
                    </ul>
                </section> */}

            </div>
        )
    }
}

export default LandingPageContentSection;