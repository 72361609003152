import './LandingPage.css';
import { LandingPageHeroSection, LandingPageContentSection } from '../../components'


function LandingPage() {
  return (
    <div className="LandingPage" >
      <LandingPageHeroSection />
      <LandingPageContentSection />
    </div>
  );
}

export default LandingPage;
