import React, { Component } from 'react'
import './ReadSecretPanel.css';
import { backendUrl } from '../../conf'

class ReadSecretPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          mounted: false,
          secretId: props.secretId,
          value: 'props.secretId'
        };
        this.handleChange = this.handleChange.bind(this);
      }

      async getData(url = '') {
        const response = await fetch(url, {
          method: 'GET',
          cors: "no-cors"
        });

        const json = await response.json()
        return json;
      }

      handleChange(event) {
        this.setState({value: event.target.value});
      }      

      componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
        
        // Guard clause
        if (this.secretId) {
          this.setState({value: "Sorry, secret has already been read or it was never there!"});
          return
        }

        this.getData(`${backendUrl}/${this.state.secretId}`)
          .then(response => {
            if(response.data.Attributes) {
              const decodedSecret = atob(response.data.Attributes.secret)
              this.setState({value: decodedSecret});
            } else {
              this.setState({value: "Sorry, secret has already been read or it was never there!"});
            }
        })
      }

      render() {
        return (
          <div className="ReadSecretPanel">
              <textarea className='ReadSecretPanel-textarea' value={this.state.value} onChange={this.handleChange} />
          </div>
        )
      }
}

export default ReadSecretPanel;