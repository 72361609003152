import './ReadSecretPage.css';
import { ReadSecretPanel } from '../../components';
import { useLocation } from 'react-router-dom'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ReadSecretPage() {
  let query = useQuery();
  return (
    <div className="ReadSecretPage">
      <header className="ReadSecretPage-header wrapper style2">
        <ReadSecretPanel secretId={query.get("mySecret")} />
      </header>
    </div>
  );
}

export default ReadSecretPage;
